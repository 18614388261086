import React, { useState } from "react"
import { navigate } from "gatsby"

import { getUser } from "../utils/auth"
import { API_URL } from "../utils/helpers"

import Layout from "../elements/Layout"
import Helmet from "../elements/Helmet"

import Nav from "../components/Nav/index"

import Input from "../components/form/Input"
import Msg from "../components/form/Msg"
import Submit from "../components/form/Submit"

import { Container, Grid } from "../components/charity-add/styled"

const CharityAdd = () => {
  const [names, setNames] = useState({})
  const [errors, setErrors] = useState([])
  const [msg, setMsg] = useState({})

  const handleUpdate = (name, value) => {
    setNames((names) => ({ ...names, [name]: value }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setErrors([])
    setMsg({
      type: "working",
      text: "",
    })

    const url = new URL(`${API_URL}/?f=adminCharityAdd&admin=1`)
    let formData = new FormData()
    Object.entries(names).forEach(([key, value]) => {
      formData.append(key, value)
    })
    formData.append("token", getUser().token || "")

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      })
      const json = await response.json()
      if (json && json.resp === 1) {
        navigate(json.next)
      } else {
        setErrors(json.fields)
        setMsg({
          type: "error",
          text: json.text,
        })
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  return (
    <Layout>
      <Helmet />
      <Container>
        <Nav
          sections={[
            { name: "Admin Home", to: "/" },
            { name: "", to: "" },
            { name: "Charity", to: "" },
          ]}
          title="Add Charity"
        />

        <p className="no-top-margin">
          Enter the charity name and a unique code to start the process of
          adding a new charity to the database.
        </p>

        <Grid>
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="two">
              <div>
                <Input
                  type="text"
                  label="Charity Name"
                  req={true}
                  name="name"
                  value={names.name}
                  update={handleUpdate}
                  errors={errors}
                />
              </div>
              <div>
                <Input
                  type="text"
                  label="Charity Code"
                  req={true}
                  name="code"
                  value={names.code}
                  update={handleUpdate}
                  errors={errors}
                />
              </div>
            </div>

            {msg.type && <Msg data={msg} />}

            {msg.type !== "working" && <Submit label="Add & Continue" />}
          </form>
        </Grid>
      </Container>
    </Layout>
  )
}

export default CharityAdd
